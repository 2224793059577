import React from 'react';
import { graphql } from 'gatsby';
import anime from 'animejs';
import Link from 'gatsby-plugin-transition-link';

//COMPONENTS
import Layout from 'components/layout';
import { LinkHoverColor, getVar } from 'utils/common';
import { MediaCheck } from 'utils/mediacheck';
// import { ImageLoadClass } from 'utils/loader';
import ImageParser from 'components/imageParser';

//SOURCES
import 'stylesheet/archives.scss';
import { GlobalContext } from '../context/GlobalContext';

const Archives = ({ location, data }) => {
  // useEffect(() => {
  //   const imageLoader = new ImageLoadClass({
  //     parent: 'main#Archives',
  //     postload: () => {
  //       if (document.querySelector('main#Archives'))
  //         document
  //           .querySelector('main#Archives')
  //           .classList.remove('imageloading');
  //     },
  //     post_imageload: image => {
  //       image.parentElement.classList.add('loaded');
  //     },
  //   });
  //   return () => {
  //     imageLoader.kill();
  //   };
  // }, []);
  return (
    <GlobalContext.Consumer>
      {(context) => (
        <Layout
          titleText={'Archives'}
          mainID={'Archives'}
          // className='imageloading'
          location={location}
        >
          <div>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <Link
                key={index}
                to={`${node.fields.slug}`}
                exit={{
                  length: 1,
                  trigger: ({ exit, node }) => {
                    if (typeof window !== 'undefined') {
                      document.body.classList.add('transitioning');
                      context.toggleMobileMenu(false);
                    }

                    if (MediaCheck.touch()) {
                      const scrollTop =
                        (document.scrollingElement &&
                          document.scrollingElement.scrollTop) ||
                        document.body.scrollTop ||
                        window.pageYOffset;

                      node.style.overflowY = 'hidden';
                      node.style.height = `${window.innerHeight}px`;
                      node.scrollTop = scrollTop;
                    }
                  },
                }}
                entry={{
                  delay: 0,
                  trigger: ({ entry, node }) => {
                    node.querySelector('main').style.boxShadow =
                      '0px 0px 20px rgba(0, 0, 0, 0.25)';
                    anime({
                      targets: node.querySelector('main'),
                      left: {
                        value: [getVar.windowW(), 0],
                        duration: 750,
                        delay: 0,
                        easing: 'easeInOutCubic',
                      },
                    });
                    anime({
                      targets: document.querySelector('.viewinfo-archive'),
                      translateX: {
                        value: [getVar.windowW(), 0],
                        duration: 750,
                        delay: 0,
                        easing: 'easeInOutCubic',
                      },
                    });
                    anime({
                      targets: document.querySelector('.mobilebackbutton'),
                      translateX: {
                        value: [getVar.windowW(), 0],
                        duration: 750,
                        delay: 0,
                        easing: 'easeInOutCubic',
                      },
                    });
                  },
                }}
                onMouseEnter={LinkHoverColor}
                onMouseLeave={LinkHoverColor}
              >
                <div>
                  <ImageParser
                    className={`${node.frontmatter.coverimage.position_x}${node.frontmatter.coverimage.position_y}`}
                    imgStyle={{
                      objectFit: 'cover',
                      objectPosition: `${node.frontmatter.coverimage.position_x} ${node.frontmatter.coverimage.position_y}`,
                      width: '100%',
                      height: '100%',
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#DDDDDD',
                    }}
                    loading='eager'
                    relativepath={node.frontmatter.coverimage.file.publicURL}
                    childImageSharp={
                      node.frontmatter.coverimage.file.childImageSharp
                    }
                    alt='Brownfox Project'
                  />
                  {/* <img
                    alt='Brownfox Project'
                    className={`${node.frontmatter.coverimage.position_x}${node.frontmatter.coverimage.position_y}`}
                    src={checkFile(node.frontmatter.coverimage.file)}
                    loading='lazy'
                  /> */}
                </div>
                <div>
                  {node.frontmatter.category} — {node.frontmatter.title}
                </div>
              </Link>
            ))}
          </div>
        </Layout>
      )}
    </GlobalContext.Consumer>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          issetting: { eq: false }
          contenttype: { eq: "archives" }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY")
            category
            slug
            coverimage {
              file {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                  )
                }
              }
              position_x
              position_y
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

export default Archives;